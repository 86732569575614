@import "reset.css";
@import "npm:@fontsource-variable/rubik";
@import "typography.css";

body {
  font-family: "Rubik Variable", sans-serif;
}

* {
  font-family: inherit;
}

html,
body {
  height: 100%;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
}
