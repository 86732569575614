/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.superskills {
  --th-height: 135px;
  --th-width: 20px;
  --size: 175px;
}
th.rotate {
  white-space: nowrap;
  height: var(--th-height);
  width: var(--th-width);
  max-width: var(--th-width);
  white-space: nowrap;
}

th.rotate > div {
  width: var(--size);
  transform: translate(
      calc(var(--size) / -1.41421356237 - 1px - 1em),
      10px
        /*
      calc(var(--th-height) * -1),
      calc(var(--th-width) * -0.5)
      */
    )
    rotate(45deg);
  display: flex;
  justify-content: flex-end;
}
th.rotate > div > span {
  border-bottom: 1px solid #ccc;
  display: block;
  text-align: end;
  flex-grow: 1;
}

table {
  white-space: nowrap;
}
