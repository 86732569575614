html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.superskills {
  --th-height: 135px;
  --th-width: 20px;
  --size: 175px;
}

th.rotate {
  white-space: nowrap;
  height: var(--th-height);
  width: var(--th-width);
  max-width: var(--th-width);
  white-space: nowrap;
}

th.rotate > div {
  width: var(--size);
  transform: translate(calc(var(--size) / -1.41421 - 1px - 1em), 10px  ) rotate(45deg);
  justify-content: flex-end;
  display: flex;
}

th.rotate > div > span {
  text-align: end;
  border-bottom: 1px solid #ccc;
  flex-grow: 1;
  display: block;
}

table {
  white-space: nowrap;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 300 900;
  src: url("rubik-cyrillic-ext-wght-normal.42803e42.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 300 900;
  src: url("rubik-cyrillic-wght-normal.1584c3f8.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 300 900;
  src: url("rubik-hebrew-wght-normal.d4ba82f7.woff2") format("woff2-variations");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 300 900;
  src: url("rubik-latin-ext-wght-normal.6bb8b940.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 300 900;
  src: url("rubik-latin-wght-normal.b89cfb98.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+300-301, U+303-304, U+308-309, U+323, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1 {
  color: #000c;
  text-rendering: optimizelegibility;
  margin: 0 0 1.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.25rem;
}

h2 {
  color: #000c;
  text-rendering: optimizelegibility;
  margin: 0 0 1.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.51572rem;
  font-weight: bold;
  line-height: 2.25rem;
}

h3 {
  color: #000c;
  text-rendering: optimizelegibility;
  margin: 0 0 1.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.31951rem;
  font-weight: bold;
  line-height: 2.25rem;
}

h4 {
  color: #000c;
  text-rendering: optimizelegibility;
  margin: 0 0 1.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
}

h5 {
  color: #000c;
  text-rendering: optimizelegibility;
  margin: 0 0 1.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: .87055rem;
  font-weight: bold;
  line-height: 1.5rem;
}

h6 {
  color: #000c;
  text-rendering: optimizelegibility;
  margin: 0 0 1.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: .81225rem;
  font-weight: bold;
  line-height: 1.5rem;
}

body {
  font-family: Rubik Variable, sans-serif;
}

* {
  font-family: inherit;
}

html, body {
  height: 100%;
}

#app {
  height: 100%;
  flex-direction: column;
  padding: 16px;
  display: flex;
}

/*# sourceMappingURL=index.0b965e81.css.map */
